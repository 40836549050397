/* Enhanced table styling */
.wallet-table-container {
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.results-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 10px;
  overflow: hidden;
}

.results-table th {
  background: #2c3e50;
  color: white;
  font-weight: 600;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.results-table td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #edf2f7;
  font-size: 15px;
  color: #2d3748;
}

.results-table tr:hover {
  background-color: #f8fafc;
}

.results-table tr:last-child td {
  border-bottom: none;
}

/* Jettons overview styling */
.jettons-overview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.jettons-names, .jettons-balances, .jettons-prices {
  display: flex;
  gap: 15px;
  justify-content: center;
  padding: 8px;
  overflow-x: auto;
  white-space: nowrap;
}

.jetton-name, .jetton-balance, .jetton-price {
  background: #f8fafc;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-size: 14px;
  color: #2d3748;
  min-width: 100px;
  text-align: center;
}

.jetton-name {
  font-weight: 500;
}

.jetton-price {
  color: #4a5568;
}

/* Container and header styling */
.ton-wallet-checker {
  max-width: 1750px;
  margin: 40px auto;
  padding: 30px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.header-section {
  text-align: center;
  margin-bottom: 30px;
}

.header-section h1 {
  color: #2d3748;
  font-size: 32px;
  margin-bottom: 10px;
}

.total-balance {
  background: #2c3e50;
  color: white;
  padding: 15px 25px;
  border-radius: 10px;
  display: inline-block;
  margin: 20px 0;
  font-size: 18px;
  font-weight: 600;
}
.wallet-address{
  width: 50px;
}
.progress-section {
  text-align: center;
  margin: 20px 0;
  padding: 15px;
  background: #edf2f7;
  border-radius: 10px;
}
.wallet-input {
  width: 100%;
  padding: 15px;
  border: 2px solid #e2e8f0;
  border-radius: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
}

.wallet-input:focus {
  border-color: #3182ce;
  outline: none;
}
/* Input styling */
.input-section {
  max-width: 800px;
  margin: 0 auto 30px;
}

.wallet-input {
  width: 100%;
  padding: 15px;
  border: 2px solid #e2e8f0;
  border-radius: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
}

.wallet-input:focus {
  border-color: #3182ce;
  outline: none;
}

.min-balance-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.min-balance-input input {
  padding: 10px 15px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 16px;
  width: 150px;
}

.check-button {
  background: #3182ce;
  color: white;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;
}

.check-button:hover {
  background: #2c5282;
  transform: translateY(-2px);
}

.check-button:disabled {
  background: #cbd5e0;
  cursor: not-allowed;
  transform: none;
}
/* Keep all existing styles and add/modify these */
/* Keep all existing styles and add/modify these */

.jettons-list {
  display: flex;
  gap: 12px;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  overflow-x: auto;
}

.jetton-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 130px;
  background: #f8fafc;
  border-radius: 8px;
  padding: 8px;
  flex-shrink: 0;
}

.jetton-name {
  font-weight: 600;
  color: #2d3748;
  font-size: 14px;
  text-align: center;
  padding: 4px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jetton-balance {
  color: #4a5568;
  font-family: monospace;
  font-size: 13px;
  padding: 4px;
  width: 100%;
  text-align: center;
  border-top: 1px solid #edf2f7;
}

.jetton-price {
  color: #2c5282;
  font-family: monospace;
  font-size: 13px;
  padding: 4px;
  width: 100%;
  text-align: center;
  border-top: 1px solid #edf2f7;
}

/* Scrollbar styling */
.jettons-list::-webkit-scrollbar {
  height: 6px;
}

.jettons-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.jettons-list::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 3px;
}

.jettons-list::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

/* Table cell adjustments */
.jettons-cell {
  padding: 0 !important;
}

.results-table td {
  vertical-align: top;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .jetton-item {
    min-width: 110px;
  }
}

.tabs {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.tab-button.active {
  background: #0066cc;
  color: white;
}

.tab-button:hover {
  background: #0066cc;
  color: white;
}

.tokens-content,
.nft-content {
  margin-top: 20px;
}

.token-controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.loading-nfts {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}

/* Update existing styles */
.nft-section {
  margin-top: 0;
}
.token-suggestions {
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-top: 5px;
}

.token-suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.token-suggestion-item:hover {
  background: #f7fafc;
}

.token-suggestion-item.selected {
  background: #e2e8f0;
}

.selected-indicator {
  color: #2c5282;
  margin-left: 5px;
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wallet-input {
    font-size: 14px;
    padding: 12px;
  }

  .check-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .min-balance-input {
    flex-direction: column;
    gap: 10px;
  }

  .token-input-wrapper {
    flex-direction: column;
    gap: 5px;
  }
}

/* Scrollbar styling */
.input-section::-webkit-scrollbar {
  width: 8px;
}

.input-section::-webkit-scrollbar-track {
  background: #edf2f7;
}

.input-section::-webkit-scrollbar-thumb {
  background: #a0aec0;
  border-radius: 8px;
}

.input-section::-webkit-scrollbar-thumb:hover {
  background: #718096;
}
.nft-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 5px;
}

.nft-card {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  padding: 8px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s;
}

.nft-card:hover {
  transform: translateY(-4px);
}

.nft-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 6px;
}

.no-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 4px;
  height: 120px;
  color: #999;
}

.nft-details {
  padding-top: 4px;
}

.nft-name {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
}

.nft-collection {
  font-size: 10px;
  color: #666;
}

.compact {
  padding: 4px;
  margin-bottom: 0;
  font-size: 12px;
}
.transaction-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
  border: 1px solid #e9ecef;
}

.transaction-type {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.transaction-amount {
  font-weight: 600;
}

.transaction-amount[data-type="received"] {
  color: #28a745;
}

.transaction-amount[data-type="sent"] {
  color: #dc3545;
}

.transaction-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.transaction-address {
  font-family: monospace;
}

.transaction-comment {
  font-size: 0.875rem;
  color: #6c757d;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transaction-meta {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.transaction-time {
  white-space: nowrap;
}

.transaction-fee {
  white-space: nowrap;
}

.transaction-hash {
  color: #6c757d;
}

.loading-transactions {
  text-align: center;
  padding: 2rem;
  color: #6c757d;
}

/* Update existing tab styles */
.tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.tab-button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab-button.active {
  border-bottom-color: #007bff;
  color: #007bff;
}